<!-- Mto particular de Usuarios -->

<template>
  <div class="usuM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">

          <div style="width:860px">
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">

                <!-- Botoneras -->
                <div class="pt-2 pl-2 d-flex">

                  <!-- Mto -->
                  <baseBtraMto
                    v-if="!readOnly"
                    :perm="permMto"
                    :modulo="btMtoCfg"
                    :estado="estado"
                    @onEvent="execAccion">
                  </baseBtraMto>

                  <baseBtraExtra
                    style="margin-left:50px"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>

                </div>

                <!-- Controles del Mto -->
                <div class="conflex pl-2" style="margin-top:10px">

                  <div class="columna" style="width:80%">

                    <!-- identificacion -->
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <uiText
                          style="width:20%"
                          styleInput="font-weight: bold;"
                          v-model="ct.cta[2]"
                          :label="ct.cta[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:80%"
                          styleInput="font-weight: bold;"
                          v-model="ct.name[2]"
                          :label="ct.name[1]"
                          :disabled="noEdit">
                        </uiText>
                        {{ ct.id[2] }}
                      </div>
                    </v-sheet>

                    <!-- Datos -->
                    <div class="cab">DATOS GENERALES</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <uiText
                          style="width:80%"
                          v-model="ct.rs[2]"
                          :label="ct.rs[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:20%"
                          v-model="ct.nif[2]"
                          :label="ct.nif[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:80%"
                          v-model="ct.dir[2]"
                          :label="ct.dir[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:20%"
                          v-model="ct.cpo[2]"
                          :label="ct.cpo[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:55%"
                          v-model="ct.pob[2]"
                          :label="ct.pob[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:35%"
                          v-model="ct.pro[2]"
                          :label="ct.pro[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:10%"
                          v-model="ct.pais[2]"
                          :label="ct.pais[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:30%"
                          v-model="ct.tfn1[2]"
                          :label="ct.tfn1[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:30%"
                          v-model="ct.tfn2[2]"
                          :label="ct.tfn2[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:30%"
                          v-model="ct.fax[2]"
                          :label="ct.fax[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          v-model="ct.email1[2]"
                          :label="ct.email1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-model="ct.email2[2]"
                          :label="ct.email2[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>
                    </v-sheet>

                    <div class="cab">ECONÓMICOS</div>
                    <v-sheet width="100%" v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <uiText
                          style="width:50%"
                          v-model="ct.banco[2]"
                          :label="ct.banco[1]"
                          :disabled="noEdit">
                        </uiText>
                        <uiText
                          style="width:50%"
                          v-model="ct.bancta[2]"
                          :label="ct.bancta[1]"
                          :disabled="noEdit">
                        </uiText>

                        <!-- <uiDate
                          v-model="ct.fhalta[2]"
                          :label="ct.fhalta[1]"
                          :disabled="noEdit">
                        </uiDate> -->

                      </div>
                    </v-sheet>

                    <!-- Observaciones  -->
                    <!-- <div class="cab" style="width:310px">OBSERVACIONES</div> -->
                    <v-sheet v-bind="$cfg.styles.marco">
                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs"
                        :edicion="!noEdit">
                      </uiObs3>
                    </v-sheet>

                  </div>
                </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>


    <!-- Ventana de Componentes Dinámicos  -->
    <component
      :is="componenteDinamico"
      syncUpdate
      :storeRaiz="storeName"
      :masterStore="storeName"
      :readOnly="readOnly"
      tipoDocumento='4'
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>

  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import commonDocsF from "@/components/commonDocsF.vue";

  export default {
    mixins: [mixM],
    components: {
      baseHeader,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,
      commonDocsF
    },

    props: {},

    data() {
      return {
        stIni: {
          api: "ctasM",
          name:"ctasM",
          titulo:"CUENTAS",
          recordAccess:"local",
          mView:'ctasM',
          pView:[]
        },

        //itemsDeptos: [],
        //itemsNiveles: []
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion": 'documentos', "btn": "docs" },
        ];

        // departamentos
        //this.itemsDeptos= this.$store.state.datos_iniciales.deptos;

        // niveles
        /* for (let x=1; x<10; x++) {
          this.itemsNiveles.push({ id:String(x), name:x});
        } */
      },


      //
      iniDataApi() {
      },

      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },


    }
  };
</script>
